import { createTsForm, createUniqueFieldSchema } from "@ts-react/form";
import { type ComponentProps } from "react";
import {
  type FieldValues,
  type Path,
  type UseFormReturn,
  useFormContext,
} from "react-hook-form";
import { Form, type FormProps, Theme } from "tamagui";
import { z } from "zod";

import { type FormError } from "@medbillai/graphql-types";

import {
  AddressField,
  AddressSchema,
  BooleanCheckboxField,
  BooleanField,
  BooleanSwitchField,
  CardMultiSelectField,
  DateTextField,
  ListSelectField,
  NumberField,
  RadioGroupField,
  SecurityAnswersField,
  SecurityAnswersSchema,
  SelectField,
  TextAreaField,
  TextField,
  dateRegex,
} from "./FormFields";
import { AuthenticationRadioGroupField } from "./FormFields/AuthenticationRadioGroupField";
import { CardSelectField } from "./FormFields/CardSelectField";
import { FormError as FormErrorUI } from "./FormFields/FormError";
import {
  PhoneNumberField,
  validatePhoneNumber,
} from "./FormFields/PhoneNumberField";
import { ZipCodeField, zipCodeRegex } from "./FormFields/ZipCodeField";
import { FormWrapper } from "./FormWrapper";

export const gqlFormError = (
  error: FormError | Record<string, unknown> | null | undefined,
): FormError | undefined => {
  if (error && typeof error === "object" && error.__typename === "FormError") {
    return error as FormError;
  }
  return;
};

export const formFields = {
  text: z.string(),
  textarea: createUniqueFieldSchema(z.string(), "textarea"),
  /**
   * input that takes number
   */
  number: z.number(),
  /**
   * adapts to native switch on native, and native checkbox on web
   */
  boolean: z.boolean(),
  /**
   * switch field on all platforms
   */
  boolean_switch: createUniqueFieldSchema(z.boolean(), "boolean_switch"),
  /**
   * checkbox field on all platforms
   */
  boolean_checkbox: createUniqueFieldSchema(z.boolean(), "boolean_checkbox"),
  /**
   * make sure to pass options={} to props for this
   */
  select: createUniqueFieldSchema(z.string(), "select"),
  /**
   * example of how to handle more complex fields
   */
  address: createUniqueFieldSchema(AddressSchema, "address"),
  /**
   * takes in options={} like select
   */
  radio_group: createUniqueFieldSchema(z.string(), "radio_group"),
  /**
   * takes in options={} like select
   */
  authenticator_radio_group: createUniqueFieldSchema(
    z.number().int(),
    "authenticator_radio_group",
  ),
  date_text: createUniqueFieldSchema(
    z.string().regex(dateRegex, "Date must be in MM/DD/YYYY format."),
    "date_text",
  ),
  date_text_raw: createUniqueFieldSchema(z.string(), "date_text_raw"),
  rpa_security_questions: createUniqueFieldSchema(
    SecurityAnswersSchema,
    "rpa_security_questions",
  ),
  zip_code: createUniqueFieldSchema(
    z.string().regex(zipCodeRegex, "Zip must be a 5-digit number"),
    "zip_code",
  ),
  zip_code_raw: createUniqueFieldSchema(z.string(), "zip_code_raw"),
  phone: createUniqueFieldSchema(
    z.string().refine(validatePhoneNumber, "Invalid phone number"),
    "phone_raw",
  ),
  phone_raw: createUniqueFieldSchema(z.string(), "phone"),
  card_multi_select: createUniqueFieldSchema(
    z.array(z.string()),
    "card_selections",
  ),
  card_select: createUniqueFieldSchema(z.string(), "card_selection"),
  list_select: createUniqueFieldSchema(z.string(), "list_selection"),
};

// function createFormSchema<T extends ZodRawShape>(getData: (fields: typeof formFields) => T) {
//   return z.object(getData(formFields))
// }

const mapping = [
  [formFields.text, TextField] as const,
  [formFields.textarea, TextAreaField] as const,
  [formFields.number, NumberField] as const,
  [formFields.boolean, BooleanField] as const,
  [formFields.boolean_switch, BooleanSwitchField] as const,
  [formFields.boolean_checkbox, BooleanCheckboxField] as const,
  [formFields.select, SelectField] as const,
  [formFields.radio_group, RadioGroupField] as const,
  [formFields.address, AddressField] as const,
  [formFields.date_text, DateTextField] as const,
  [formFields.date_text_raw, DateTextField] as const,
  [formFields.zip_code, ZipCodeField] as const,
  [formFields.zip_code_raw, ZipCodeField] as const,
  [formFields.phone, PhoneNumberField] as const,
  [formFields.phone_raw, PhoneNumberField] as const,
  [
    formFields.authenticator_radio_group,
    AuthenticationRadioGroupField,
  ] as const,
  [formFields.rpa_security_questions, SecurityAnswersField],
  [formFields.card_multi_select, CardMultiSelectField] as const,
  [formFields.card_select, CardSelectField] as const,
  [formFields.list_select, ListSelectField] as const,
] as const;

const FormComponent = (props: FormProps) => {
  return (
    <Form asChild {...props}>
      <FormWrapper tag="form">{props.children}</FormWrapper>
    </Form>
  );
};

const _SchemaForm = createTsForm(mapping, {
  FormComponent,
});

// Unfortunately, the stuff in here from Takeout doesn't pass our stricter
// typechecking/ESLint requirements, so we have to disable react/prop-types
// for a few lines. Hopefully, Tamagui will fix this soon. Introduced here:
// https://github.com/tamagui/unistack/commit/6326fa04bfdcf071f9ecd48813794c8c1b0fe5f0
export const SchemaForm: typeof _SchemaForm = ({ ...props }) => {
  const renderAfter: ComponentProps<typeof _SchemaForm>["renderAfter"] =
    // eslint-disable-next-line react/prop-types
    props.renderAfter
      ? vars => (
          // eslint-disable-next-line react/prop-types
          <FormWrapper.Footer>{props.renderAfter?.(vars)}</FormWrapper.Footer>
        )
      : undefined;

  return (
    <_SchemaForm {...props} renderAfter={renderAfter}>
      {(fields, context) => (
        <FormWrapper.Body>
          {/* eslint-disable-next-line react/prop-types */}
          {props.children
            ? // eslint-disable-next-line react/prop-types
              props.children(fields, context)
            : Object.values(fields)}
        </FormWrapper.Body>
      )}
    </_SchemaForm>
  );
};

// handle manual errors (most commonly coming from a server) for cases where it's not for a specific field - make sure to wrap inside a provider first
// stopped using it cause of state issues it introduced - set the errors to specific fields instead of root for now
export const RootError = () => {
  const context = useFormContext();
  const errorMessage = context?.formState?.errors?.root?.message;

  return (
    <Theme name="red">
      <FormErrorUI errorMessage={errorMessage} />
    </Theme>
  );
};

/**
 * Applies errors from a standard FormError object to the form. Accepts a unioned
 * type which would typically be returned from a mutation. If errors were found,
 * this function returns true.
 * @param expectExtraErrors can be used when a multi step form has a list of
 * errors that apply to all pages and we do not want to throw an error for
 * errors that will show on alternative pages
 */
export const applyFormErrors = <TFieldValues extends FieldValues>(
  form: UseFormReturn<TFieldValues>,
  error: FormError | Record<string, unknown>,
  expectExtraErrors: boolean = false,
): boolean => {
  form.clearErrors();
  const formErr = gqlFormError(error);
  if (!formErr) {
    return false;
  }
  let foundError = false;
  if (formErr.message) {
    form.setError("root", { type: "custom", message: formErr.message });
    foundError = true;
  }
  const allFields = form.getValues();
  for (const field of formErr.fields || []) {
    if (!Object.keys(allFields).includes(field.path)) {
      if (!expectExtraErrors) {
        console.error(`Received form error for unknown field: ${field.path}`);
      }
      continue;
    }
    form.setError(field.path as Path<TFieldValues>, {
      type: "custom",
      message: field.message ?? "Invalid",
    });
    foundError = true;
  }
  if (!foundError) {
    form.setError("root", {
      type: "custom",
      message: "An unknown error occurred",
    });
  }
  return true;
};
