import { useTsController } from "@ts-react/form";
import { type NamedExoticComponent, useId } from "react";
import { useFormContext } from "react-hook-form";
import { ListItem, XStack, YGroup } from "tamagui";

import type { IconProps } from "@tamagui/helpers-icon";
import {
  ChevronRight,
  Globe,
  Headset,
  HelpCircle,
  ListChecks,
  Receipt,
  Search,
  ShieldCheck,
  XCircle,
} from "@tamagui/lucide-icons";

import { textPrimaryHex } from "../../themes/colors-granted";
import { WithSeparator } from "../Separator";
import { Paragraph } from "../Typography";
import { Fieldset } from "./Fieldset";
import { FormError } from "./FormError";

// TODO make this more flexible, allow for images and emojis so we can create a
// shared util with other form fields, will be needed for backend dynamic flow
// fields
type IconMapping = {
  [key: string]: NamedExoticComponent<IconProps>;
};

const iconMapping: IconMapping = {
  Receipt: Receipt,
  ListChecks: ListChecks,
  Globe: Globe,
  Search: Search,
  ShieldCheck: ShieldCheck,
  XCircle: XCircle,
  Headset: Headset,
  HelpCircle: HelpCircle,
};

type IconLookupKey = keyof typeof iconMapping;

export type ListSelectOption = {
  id: string;
  icon?: IconLookupKey;
  label: string;
};

export type ListSelectItemProps = {
  id: string;
  icon: IconLookupKey;
  label: string;
  disabled?: boolean;
  onPress?: () => void;
};

export const ListSelectItem = ({
  id,
  icon,
  label,
  disabled,
  onPress,
}: ListSelectItemProps) => {
  let Icon: NamedExoticComponent<IconProps> | undefined;

  if (icon && iconMapping[icon]) {
    Icon = iconMapping[icon];
  }

  return (
    <YGroup.Item>
      <ListItem
        id={id}
        hoverTheme
        pressTheme
        title={<Paragraph color={textPrimaryHex}>{label}</Paragraph>}
        icon={Icon ? <Icon size="$1.5" color={textPrimaryHex} /> : undefined}
        iconAfter={<ChevronRight size="$1.5" color="$textSecondary" />}
        disabled={disabled}
        onPress={onPress}
        paddingVertical="$5"
      ></ListItem>
    </YGroup.Item>
  );
};

export type ListSelectFieldProps = {
  native?: boolean;
};

export const ListSelectField = ({
  options,
  onSelect,
}: {
  options: ListSelectOption[];
  onSelect?: () => Promise<void>;
} & Pick<ListSelectFieldProps, "native">) => {
  const {
    field,
    error,
    formState: { isSubmitting },
  } = useTsController<string>();

  const { resetField } = useFormContext();

  const id = useId();

  const disabled = isSubmitting;

  // This function does not currently support de-selecting a card
  // It is intended to be used when we want to fire an event when a
  // card is selected and then submit.
  async function handleSelect(option: string) {
    // marks the field as dirty
    resetField(field.name);
    field.onChange(option);
    await onSelect?.();
  }

  return (
    <Fieldset>
      <XStack flex={1} alignContent="center" justifyContent="center">
        <YGroup width="100%" size="$5" id={id}>
          <WithSeparator>
            {options.map(option => {
              return (
                <ListSelectItem
                  key={option.id}
                  disabled={disabled}
                  label={option.label}
                  id={option.id}
                  icon={option.icon as IconLookupKey}
                  onPress={() => void handleSelect(option.id)}
                />
              );
            })}
          </WithSeparator>
        </YGroup>
      </XStack>
      <FormError errorMessage={error?.errorMessage} />
    </Fieldset>
  );
};
