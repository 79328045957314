import React from "react";
import { Separator as DefaultSeparator } from "tamagui";

import { styled } from "@tamagui/core";

import { hairline } from "../utils/hairline";

export const Separator = styled(DefaultSeparator, {
  borderColor: "$strokeSecondary",
  borderBottomWidth: hairline,

  variants: {
    vertical: {
      true: {
        borderRightWidth: hairline,
        borderBottomWidth: 0,
      },
    },
  },
});

/**
 * WithSeparator is a component that wraps a list of children and adds a
 * separator between each child.
 */
export const WithSeparator = ({
  separator,
  children,
}: {
  separator?: React.ReactNode;
  children: React.ReactNode;
}) => {
  return (
    <>
      {React.Children.toArray(children).map((child, index, array) => (
        <React.Fragment key={index}>
          {child}
          {index < array.length - 1 && (separator ?? <Separator />)}
        </React.Fragment>
      ))}
    </>
  );
};
